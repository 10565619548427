.module-hero-simple {
  border-bottom: 0.5rem solid $orange;
  position: relative;
  z-index: 10;
  overflow: hidden;

  &__wrapper {
    @extend %gradient-overlay;
    width: 100%;
    height: 100%;

    @for $i from 0 through 10 {
      &--gradient-#{10 * $i} {
        &:before {
          opacity: #{0.1 * $i};
        }
      }
    }
  }

  &__background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; // parallax overflow not necessary when at the top of the page
    background: {
      size: cover;
      position: 50%;
      color: $gray;
    }

    @for $i from 0 through 10 {
      &--focus-#{$i * 10} {
        background-position: #{$i * 10%};
      }
    }

    &--focus-right {
      background-position: 65%;
    }

    &--focus-left {
      background-position: 35%;
    }

    @media only screen and (min-width: $screen-sm-min) {
      background-position: 50%;
    }
  }

  &__content {
    position: relative;
    width: 100%;
    height: auto;
    padding: 7rem 0 11rem;

    @media only screen and (min-width: $screen-sm-min) {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 40.5rem;
      padding: 11rem 0;
    }
  }

  .container {
    position: relative;
    z-index: 100;
    margin: 0;

    h1 {
      @extend .h1;
      margin: 0;
      color: $white;
      text-align: center;
    }
  }
}
