$input-bg: #fff !default;
$input-color: $black !default;
$input-border: #ddd !default;
$input-border-focus: #999 !default;
$input-label: $black !default;
$input-error: $orange !default;
$input-shadow: 1px 1px 2px #eee inset !default;
$input-transition: all 0.3s ease-in-out !default;

$form-required: $orange !default;
$form-muted: #858585 !default;

$form-font-size: 1.6rem !default;
$form-line-height: $form-font-size * 1.5 !default;
$form-space: $form-line-height / 2 !default;

$white: #fff !default;
$gray: #aaa !default;
