html {
  font-family: $font-family-sans-serif;
  font-size: 62.5%; // = 10px in most browsers and will make calculating rems much easier
  height: 100%;
	color: $black;
  box-sizing: border-box;

  * {
    box-sizing: inherit;
  }
}

body {
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: $site-background-color;
  scroll-behavior: smooth;
  overflow-x: hidden;

  &.menu-open {
    overflow: hidden;
  }

  *:focus {
    // remove focus state from all elements
    outline: none;
  }

  &:not(.user-is-not-tabbing) *:focus {
    // add focus state for all elements when tabbing detected
    outline-style: solid;
    outline-color: rgba(#ef4e23, 0.35);
    outline-offset: 0.5rem;
    outline-width: 0.2rem;
  }
}

main,
article,
header,
footer {
  display: block; // for old IE
}

img {
  max-width: 100%;
  height: auto;
}

%gradient-overlay {
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 90;
    background: linear-gradient(360deg, $black 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0.8;
  }
}

.table-scroll-wrap {
  max-width: 100%;
  overflow-x: auto;
  margin-bottom: 2rem;

  table {
    margin: 0 !important;
  }
}

.searchwp-highlight {
  background-color: $yellow;
  color: inherit;
}
