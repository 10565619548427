/**
 * Overrides for the simple flex templates to increase usability on tablets.
 */

.page-template-template-flex-simple-php,
.page-template-template-fullwidth-simple-php {
	.button {
		font-size: 1.65rem;
		line-height: 1.2;
	}

	.page-wrapper {
		padding: 4rem;
		height: auto;
	}

	.post-single {
		padding: 0;
	}

	.container {
		padding: 0;
	}

	@media only screen and (min-width: $screen-sm3-min) {
		.gform_wrapper .column-count-1-of-2,
		.gform_wrapper .gfmc-row-1-col-1-of-2 {
			padding-right: 4rem;
		}
	}

	.gform_confirmation_wrapper .gform_confirmation_message,
	.gf-order-table .gfield_description,
	.gf-order-table.ginput_preview,
	.gform_wrapper .gfield_description,
	.gform_wrapper .gfield_description p,
	.gform_wrapper .gsection_description,
	.gform_wrapper .ginput_product_price,
	.gform_wrapper .gfield_html p,
	.gform_wrapper .gfield_description p,
	.gform_wrapper .gf-subtotal .ginput_container input.small,
	.gform_wrapper .gf-subtotal .ginput_container .ginput_product_price,
	.gform_wrapper .gf-subtotal .gf-order-table .gfield_description p,
	.gform_wrapper label {
		font-size: 1.8rem;
	}
}
