.module-video-banner {
  position: relative;
  z-index: 10;
  overflow: hidden;

  &:before {
    // maintain aspect ratio of module
    content: '';
    display: block;
    height: 0;
    width: 0.1rem;
    padding-top: 53%;
  }

  &__background {
    position: absolute;
    left: 0;
    width: 100%;
    top: -25%;
    height: 150%; // allow for some parallax overflow
    background: {
      size: cover;
      position: 50%;
    }

    @media only screen and (min-width: $screen-sm-min) {
      top: -2.5%;
      height: 105%; // allow for some parallax overflow
    }
  }

  &__link {
    @extend .play-button;
  }
}
