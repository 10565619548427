// Text
$font-family-sans-serif: eurostile, sans-serif;
$font-family-sans-serif-condensed: eurostile-condensed, sans-serif;
$font-family-sans-serif-extended: eurostile-extended, sans-serif;
$font-family-numbers: teko, sans-serif;

// Colors
$black: #121212;
$white: #fff;
$orange: #fc4c02;
$orange-2: #dc4405;
$gray: #4a4a4a;
$yellow: #ffd100;

// Color assignments
$site-background-color: $white;
$link-color: $orange;
$link-hover-color: $black;
$hr-color: $black;
$menu-toggle-color: $black;

// Responsive sizing for media queries
$screen-min: (320em/16);
$screen-xs-min: (460em/16);
$screen-sm-min: (800em/16);
$screen-sm1-min: (850em/16);
$screen-sm2-min: (900em/16);
$screen-sm3-min: (1000em/16);
$screen-md-min: (1150em/16);
$screen-lg-min: (1300em/16);

// Measurements
$banner-height-mobile: 6rem;
$side-gutter-mobile: 3rem;
$side-gutter-mobile--wide: 2rem;

$banner-height-desktop: 9.7rem;
$side-gutter-desktop: 12rem;
$side-gutter-desktop--wide: 5rem;

$container-max-width: 144rem;
