.module-text-with-image {
  position: relative;
  z-index: 10;
  margin: 0;
  overflow: hidden;

  &__background {
    position: absolute;
    top: -25%;
    left: 0;
    width: 100%;
    height: 150%;
    background: {
      size: cover;
      position: 50%;
      color: $black;
      image: repeating-linear-gradient(
        135deg,
        transparent,
        transparent 0.8rem,
        rgba(255, 255, 255, 0.04) 0.8rem,
        rgba(255, 255, 255, 0.04) 1.4rem
      );
    }

    @media only screen and (min-width: $screen-sm-min) {
      left: 0;
      top: -10%;
      width: 100%;
      height: 120%; // allow for some parallax overflow
    }
  }

  &__wrapper {
    @media only screen and (min-width: $screen-sm-min) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &--right {
      @media only screen and (min-width: $screen-sm-min) {
        flex-direction: row-reverse;
      }
    }
  }

  &__image {
    height: 35rem;
    position: relative;
    overflow: hidden;

    @media only screen and (min-width: $screen-sm-min) {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
      width: 50%;
      height: auto;
    }
  }

  &__content {
    position: relative;
    z-index: 100;
    padding: 5rem;
    max-width: 72rem;
    margin: 0 auto;

    @media only screen and (min-width: $screen-lg-min) {
      padding: 15rem 20rem 15rem 17rem;
    }
  }

  &__content-wrapper {
    position: relative;
    z-index: 10;
    color: $white;
    overflow: hidden;

    @media only screen and (min-width: $screen-sm-min) {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
      width: 50%;
    }

    .button {
      @extend .button--white;
    }

    .title {
      @extend .h2;
      margin-top: 0;
    }

    p {
      @extend .p1;
    }
  }
}
