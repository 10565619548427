.module-aircraft-models {
  position: relative;
  z-index: 10;
  overflow: hidden;
  padding: 5.3rem 0;
  background: {
    color: $black;
  }

  @media only screen and (min-width: $screen-sm-min) {
    padding: 10.4rem 0 6.5rem;
  }

  &__background {
    position: absolute;
    left: 0;
    top: -15%;
    width: 100%;
    height: 150%; // parallax overflow not necessary when at the top of the page
    background: {
      size: cover;
      position: 50%;
      size: cover;
      image: url(../images/bg-topographical.jpg);
    }
  }

  &__list {
    position: relative;
    z-index: 100;
    color: $white;
    text-align: center;

    @media only screen and (min-width: $screen-sm-min) {
      display: flex;
      justify-content: space-around;
    }

    &,
    dd,
    dt {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    dt {
      @extend .h2;
      margin-bottom: 1.3em;

      @media only screen and (min-width: $screen-sm-min) {
        margin-bottom: 2em;
      }
    }

    dd {
      @extend .p2;

      img {
        display: block;
        margin-bottom: 3rem;

        @media only screen and (min-width: $screen-sm-min) {
          margin-bottom: 7.5rem;
        }
      }
    }
  }

  &__list-item {
    margin-bottom: 4.6rem;
    border-bottom: 0.1rem solid $white;
    padding-bottom: 5rem;

    &:last-child {
      margin-bottom: 0;
      border-bottom: 0;
      padding-bottom: 0;
    }

    &-content {
      margin: 0 auto;
      max-width: 34rem;
    }

    @media only screen and (min-width: $screen-sm-min) {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: auto;
      width: auto;
      padding: 2.3rem 5% 4rem;
      border-right: 0.1rem solid $white;
      border-bottom: 0;

      &:last-child {
        border-right: 0;
      }
    }
  }
}
