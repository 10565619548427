/* Add space on top for fixed header */
.page-wrapper {
  padding-top: $banner-height-mobile;

  @media only screen and (min-width: $screen-sm-min) {
    padding-top: $banner-height-desktop;
  }
}

/* Sticky footer */
.main {
  display: inline;

  @media only screen and (min-width: $screen-sm-min) {
    display: block;
  }
}

.page-wrapper {
  height: 100%;

  @media only screen and (min-width: $screen-sm-min) {
    display: flex;
    flex-direction: column;

    > .main {
      flex: 1 0 auto;
    }

    .content-info {
      flex-shrink: 0;
    }
  }
}

/* Container */
.container {
  max-width: $container-max-width;
  margin: 0 auto;
  padding: 0 $side-gutter-mobile;

  @media only screen and (min-width: $screen-sm-min) {
    padding: 0 $side-gutter-desktop;
  }

  &--wide {
    max-width: $container-max-width;
    padding: 0 $side-gutter-mobile--wide;

    @media only screen and (min-width: $screen-sm-min) {
      padding: 0 $side-gutter-desktop--wide;
    }
  }
}

/* Fancybox popup */
.fancybox-caption {
  @extend .p1;
  margin: 0;

  .media-credit {
    @extend .p3;
    margin-top: 0.7em;
    font: {
      style: italic;
    }
  }
}

body.fancybox-active {
  .banner,
  .page-wrapper {
    filter: blur(0.5rem);
  }
}

.fancybox-content {
  border-radius: 0.5rem;
  overflow: hidden;
}

.fancybox-slide--html {
  padding: 3rem 2rem;

  @media only screen and (min-width: $screen-sm-min) {
    padding: 5rem;
  }
}

/* Other */
.rv-count {
  color: $orange;
}

.alert-warning {
  @extend .p2;
}

img.image-border {
  border: 1px solid $black;
}

body div.pdfemb-viewer {
  margin-bottom: 4rem;
	border-color: $black;
}


/* Service - changelog */
.service-changelog {
  margin: 1em 0;
  padding: 0;

  &__item {
    list-style: none;
    padding: .75em 0 0;

    @media only screen and (min-width: $screen-sm-min) {
      display: flex;
    }
  }

  &__date {
    flex-grow   : 0;
    flex-shrink : 0;
    flex-basis  : auto;
    width: 14rem;
    @extend .h4;
    line-height: 1.5;
  }

  p {
    @extend .p2;
    margin-bottom: .5em;

    &:last-child {
      margin: 0;
    }
  }
}

