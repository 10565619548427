.banner {
	height: $banner-height-mobile;
	border-bottom: 1px solid $black;
	position: fixed;
	top: 0;
	width: 100%;
	background: $white;
	z-index: 100;

	@media only screen and (min-width: $screen-sm-min) {
		height: $banner-height-desktop;
	}

	@media only print {
		position: relative;
	}

	&.has-transition {
		transition: transform 400ms;
	}

	.container {
		@extend .container--wide;
		height: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		justify-content: space-between;
	}

	&__brand {
		display: block;
		position: relative;
		z-index: 101;
		width: 20rem;
		flex-grow: 0;
		flex-shrink: 1;
		flex-basis: auto;
		padding: 0;

		@media only screen and (min-width: $screen-sm-min) {
			width: 15rem;
			padding-right: 1rem;
		}

		@media only screen and (min-width: $screen-sm1-min) {
			width: 20rem;
		}

		@media only screen and (min-width: $screen-sm2-min) {
			width: 25rem;
		}

		@media only screen and (min-width: $screen-md-min) {
			width: 28rem;
		}

		img {
			display: block;
		}
	}

	&__nav-primary-mobile {
		display: none;
		@media only screen and (min-width: $screen-sm-min) {
		}
	}

	/* modified from: https://codepen.io/designcouch/pen/Atyop */
	&__nav-toggle {
		-webkit-appearance: none;
		display: block;
		border: 0;
		background: none;
		margin-right: 0.3rem;
		width: 1.4rem;
		height: 1.6rem;
		transform: rotate(0deg);
		transition: transform 0.5s ease-in-out;
		cursor: pointer;

		@media only screen and (min-width: $screen-sm-min) {
			&[aria-expanded="false"] {
				display: none;
			}
		}

		.bar {
			display: block;
			position: absolute;
			height: 0.2rem;
			width: 100%;
			background: $black;
			opacity: 1;
			left: 0;
			transition: transform 100ms, opacity 250ms;

			&:nth-child(1) {
				top: 0px;
			}

			&:nth-child(2),
			&:nth-child(3) {
				top: 5px;
			}

			&:nth-child(4) {
				top: 10px;
			}
		}

		&[aria-expanded="true"] {
			.bar {
				transition: transform 250ms, opacity 100ms;

				&:nth-child(1),
				&:nth-child(4) {
					opacity: 0;
				}

				&:nth-child(2) {
					transform: rotate(45deg);
				}

				&:nth-child(3) {
					transform: rotate(-45deg);
				}
			}
		}
	}

	&__nav-primary {
		display: none;
		margin: 0;
		height: 100%;
		align-items: center;

		@media only screen and (min-width: $screen-sm-min) {
			display: flex;

			.mm-wrapper_opened & {
				display: none; // hide if mobile menu is open
			}
		}

		&-container {
			display: inline-block;
			height: 100%;
		}

		ul {
			margin: 0;
			padding: 0;
			list-style: none;
		}

		li {
			margin: 0;
			padding: 0;
			display: inline-block;
		}

		&-list {
			display: flex;
			justify-content: end;
			align-items: center;
			height: 100%;
			letter-spacing: 0.7px;

			&:after {
				// submenu background
				content: "";
				position: absolute;
				top: $banner-height-desktop;
				left: 0;
				width: 100%;
				height: 0;
				background-color: $white;
				border-bottom: 0rem solid transparent;
				z-index: -1;
				transition: all 150ms ease-out;
			}

			&.submenu-open:after {
				height: 10rem;
				border-bottom: 0.1rem solid $black;
			}

			a {
				padding: 0 0.45rem;
				font: {
					size: 1.1rem;
					weight: 900;
				}
				letter-spacing: 0.1rem;
				line-height: 1;
				text-transform: uppercase;
				text-decoration: none;
				text-align: center;
				color: $black;
				transition: color 150ms;

				@media only screen and (min-width: $screen-sm3-min) {
					padding: 0 0.8rem;
					font-size: 1.2rem;
				}

				@media only screen and (min-width: $screen-md-min) {
					padding: 0 1.2rem;
					font-size: 1.3rem;
				}

				&:hover,
				&:focus {
					outline: none;
					color: $orange;
					text-decoration: none;
				}
			}

			// top level
			> li {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100%;

				> a + button {
					cursor: pointer;
					position: relative;
					width: 1px;
					height: 1px;
					vertical-align: text-top;
					background-color: transparent;
					color: $black;
					border: none;
					padding: 0;
					margin: 0;
					overflow: hidden;
					transition: all 50ms;
					vertical-align: middle;
					line-height: 1;
					text-decoration: none !important;

					&:focus {
						width: 1rem;
						height: 0.8em;
						margin-left: 0.3rem;

						@media only screen and (min-width: $screen-md-min) {
							width: 1.3rem;
							height: 1.2em;
						}
					}

					&:after {
						content: "";
						display: block;
						width: 0.3rem;
						height: 0.3rem;
						border: 0.1rem solid $black;
						border-top-color: transparent !important;
						border-right-color: transparent !important;
						-webkit-transform: rotate(-45deg);
						transform: rotate(-45deg);
						-webkit-transform-origin: center;
						transform-origin: center;
						position: absolute;
						top: 0;
						left: 0.2rem;

						@media only screen and (min-width: $screen-md-min) {
							width: 0.4rem;
							height: 0.4rem;
							border-width: 0.2rem;
						}
					}
				}

				> a {
					display: inline-block;
				}
			}
		}

		&-button {
			@extend .button;
			@extend .button--black;
			margin-left: 1.5rem;
			padding: 0.9rem 1.7rem;
			font-size: 1rem;

			@media only screen and (min-width: $screen-sm3-min) {
				margin-left: 2rem;
				padding: 0.9rem 2.4rem;
				font-size: 1.1rem;
			}
		}

		// sub-level
		li.menu-item-has-children {
			ul {
				position: absolute;
				width: 100%;
				top: $banner-height-desktop;
				left: 0;
				background-color: transparent;
				text-align: center;
				z-index: 100;
				display: none;
				justify-content: center;

				@media only screen and (min-width: $screen-sm-min) {
					padding: 0 $side-gutter-desktop--wide;
				}
			}

			li {
				height: $banner-height-desktop;
				flex-grow: 0;
				flex-shrink: 1;
				flex-basis: auto;
				display: flex;
				width: auto;

				> a {
					display: flex;
					align-items: center;
					width: 100%;
					height: 100%;
					padding: 0 0.85rem;

					@media only screen and (min-width: $screen-md-min) {
						padding: 0 1.3rem;
					}
				}

				// fancy aircraft items
				&.aircraft {
					width: 100%;
					max-width: 13rem;

					a {
						$sprite-width: 8.6rem;
						$sprite-height: 7.2rem;
						$sprite-md-size: 0.7;

						position: relative;
						padding: 5.5rem 1rem 0;
						text-transform: none;

						@media only screen and (min-width: $screen-md-min) {
							padding: 6.5rem 1rem 0;
						}

						&:before {
							content: "";
							display: block;
							position: absolute;
							top: 2.2rem;
							transform: translateX(-50%);
							left: 50%;
							width: $sprite-width * $sprite-md-size;
							height: $sprite-height * $sprite-md-size / 2;
							background: {
								size: $sprite-width * $sprite-md-size $sprite-height *
									$sprite-md-size;
								repeat: no-repeat;
							}

							@media only screen and (min-width: $screen-md-min) {
								width: $sprite-width;
								height: $sprite-height / 2;
								background-size: $sprite-width $sprite-height;
							}
						}

						&:hover,
						&:focus {
							&:before {
								background-position: 0 100%;
							}
						}
					}

					&.current-menu-item {
						a:before {
							background-position: 0 100%;
						}
					}

					&.rv-15 a:before {
						background-image: url(../images/aircraft-rv-15.svg);
					}

					&.rv-14 a:before {
						background-image: url(../images/aircraft-rv-14a.svg);
					}

					&.rv-12is a:before {
						background-image: url(../images/aircraft-rv-12is.svg);
					}

					&.rv-10 a:before {
						background-image: url(../images/aircraft-rv-10.svg);
					}

					&.rv-9 a:before {
						background-image: url(../images/aircraft-rv-9a.svg);
					}

					&.rv-8 a:before {
						background-image: url(../images/aircraft-rv-8.svg);
					}

					&.rv-7 a:before {
						background-image: url(../images/aircraft-rv-7.svg);
					}

					&.rv-4 a:before {
						background-image: url(../images/aircraft-rv-4.svg);
					}

					&.rv-3 a:before {
						background-image: url(../images/aircraft-rv-3.svg);
					}
				}
			}

			&[aria-expanded="true"] {
				> ul {
					display: flex;
					animation: fade-in 500ms forwards;
				}

				&.aircraft-submenu > ul {
					display: flex;
				}
			}

			> a:not([href]) {
				cursor: default;
			}
		}

		li.current-menu-item > a {
			color: $orange;
		}
	}
}

/**
 * MMenu
 */

.mm-menu,
.mm-panel,
.mm-panels,
.mm-navbar {
	background-color: $white;
}

.mm-menu {
	--mm-navbar-size: $banner-height-mobile;
	height: calc(100% - #{$banner-height-mobile});
	margin-top: $banner-height-mobile;

	font: {
		size: 1.5rem;
		weight: 900;
	}
	line-height: 1;
	text-transform: uppercase;

	a {
		&,
		&:active,
		&:hover,
		&:link,
		&:visited {
			color: $black;

			&:hover,
			&:focus {
				color: $orange;
			}
		}
	}

	.mm-listitem__btn,
	.mm-listitem__text {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}

	li.current-menu-item a {
		&,
		&:active,
		&:hover,
		&:link,
		&:visited {
			color: $orange;
		}
	}

	a.mm-menu__nav-button {
		@extend .button;
		@extend .button--black;
		max-width: 85%;
		width: 30rem;
		color: $white;

		&:hover,
		&:focus {
			color: $white;
		}
	}

	.mm-btn {
		&:after,
		&:before {
			border-color: $black;
		}

		&:hover,
		&:focus {
			&:after,
			&:before {
				border-color: $orange;
			}
		}
	}

	.mm-listitem:after {
		left: 0;
		border-color: #e8e8e8;
	}

	.mm-panel_has-navbar .mm-navbar {
		> * {
			padding: 2rem 0 0;
			font-size: 1.4rem;
			color: $black;
		}

		a {
			color: $black;

			&:hover,
			&:focus {
				color: $orange;
			}
		}
	}

	.mm-navbars_bottom {
		border: 0;

		> .mm-navbar {
			display: block;
			padding-top: 0.6rem;
			text-align: none;
		}
	}

	// IE11 fix so the arrows appear
	.mm-btn::after,
	.mm-btn::before {
		border-left-style: solid;
		border-top-style: solid;
		border-width: 0.2rem;
	}
}

// skip nav / bypass block
.bypass-block {
	@extend .screen-reader-text;
	position: absolute;
	top: 0;
	left: 0;

	&:focus {
		background-color: #eee;
		clip: auto !important;
		clip-path: none;
		color: #444;
		display: block;
		font-size: 1em;
		height: auto;
		left: 5px;
		line-height: normal;
		padding: 15px 23px 14px;
		text-decoration: none;
		top: 5px;
		width: auto;
		z-index: 100000; /* Above WP toolbar. */
	}
}

// PDF Viewer mobile button
body div.pdfemb-wantmobile-fsarea {
	@extend .button;
	@extend .button--black;
	display: block;
	max-height: none;
	height: auto;
	margin: 40% auto 0 !important;
	font-family: $font-family-sans-serif;
}

// menu search form and toggle
.menu-search {
	position: relative;
	padding: 1.5rem 2rem;

	.menu-toggle-search {
		position: relative;
		border: 0;
		-webkit-appearance: none;
		padding: 0 1.2rem 0 0;
		cursor: pointer;
		min-height: 1.2rem;
		margin: 0 0 0 1rem;
		background: {
			color: transparent;
			image: url("data:image/svg+xml,%0A%3Csvg aria-hidden='true' focusable='false' preserveAspectRatio='xMidYMid' data-prefix='fas' data-icon='search' class='svg-inline--fa fa-search fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z'%3E%3C/path%3E%3C/svg%3E");
			repeat: no-repeat;
			position: center bottom 0.1rem;
			size: 1.1rem 1.101rem;
		}
		text-transform: uppercase;
		line-height: 1;
		color: $black;
		font: {
			family: eurostile, sans-serif;
			size: 1.1rem;
			weight: 900;
		}

		@media only screen and (min-width: $screen-sm3-min) {
			font-size: 1.2rem;
			padding: 0 1.5rem 0 0;
			min-height: 1.5rem;
			background-size: 1.4rem 1.401rem;
		}

		@media only screen and (min-width: $screen-md-min) {
			margin: 0 1rem;
			font-size: 1.3rem;
			min-height: 1.6rem;
			background-size: 1.5rem 1.501rem;
		}

		// mobile
		.mm-menu & {
			margin: 0;
			font-size: 1.5rem;
			min-height: 1.2rem;
			background-size: 1.1rem 1.101rem;
			background-position: right top 0.3rem;
			padding: 0 1.8rem 0 0;
		}
	}

	// display form when toggled open
	.menu-toggle-search[aria-expanded="true"] + form {
		opacity: 1;
		width: 42rem;

		@media only screen and (min-width: $screen-sm3-min) {
			width: 49rem;
		}

		@media only screen and (min-width: $screen-md-min) {
			width: 58rem;
		}

		// mobile
		.mm-menu & {
			width: 100%;
			top: 0;
			padding: 0.5rem 2rem 0;
		}

		.search-field,
		.search-submit {
			display: block;
		}
	}

	.search-form {
		position: absolute;
		margin: 0;
		width: 0.1rem;
		top: 3.1rem;
		left: auto;
		right: -0.9rem;
		opacity: 0;
		transition: width 250ms ease-out, opacity 250ms ease-out;

		@media only screen and (min-width: $screen-sm3-min) {
			top: 3.3rem;
		}

		@media only screen and (min-width: $screen-md-min) {
			top: 3.3rem;
			right: 0;
		}

		// mobile
		.mm-menu & {
			top: 0;
			left: 0;
			right: auto;
		}

		.search-field {
			display: none;
			width: 100%;
			padding-right: 3rem;
			margin: 0;
			background: {
				color: white;
				image: url("data:image/svg+xml,%0A%3Csvg aria-hidden='true' preserveAspectRatio='xMidYMid' focusable='false' data-prefix='fas' data-icon='search' class='svg-inline--fa fa-search fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23999' d='M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z'%3E%3C/path%3E%3C/svg%3E");
				repeat: no-repeat;
				position: right 1rem center;
				size: 1.1rem 1.101rem;
			}

			@media only screen and (min-width: $screen-sm3-min) {
				background-size: 1.4rem 1.401rem;
			}

			@media only screen and (min-width: $screen-md-min) {
				background-size: 1.5rem 1.501rem;
			}
		}

		.search-submit {
			display: none;
			position: absolute;
			top: 0;
			right: 0;
			width: 3rem;
			height: 3.5rem;
			opacity: 0;
			padding: 0;
			border: 0;
		}
	}
}
