.module-order-links {
  position: relative;
  z-index: 10;

  .button {
    display: block;
    margin-bottom: 1.5rem;
  }

  &__item-title {
    @extend .h2;
  }

  &__wrapper {
    @media only screen and (min-width: $screen-xs-min) {
      display: grid;
      grid-gap: 2rem;
      grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (min-width: $screen-sm2-min) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (min-width: $screen-lg-min) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__item {
    margin: 0 0 3rem;
    max-width: 26.5rem;
    width: 100%;

    @media only screen and (min-width: $screen-sm-min) {
      margin-bottom: 5rem;
    }
  }
}
