.module-hero-gallery {
  position: relative;
  z-index: 10;
  overflow: hidden;
  border-bottom: 0.4rem solid $orange;
  background: $black;
  height: 100%;

  @media only screen and (min-width: $screen-sm-min) {
    border-bottom: 0.5rem solid $orange;
    height: auto;
  }

  .container {
    @extend .container--wide;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    max-height: calc(100vw * 720 / 1080); // restrict the height as a factor of viewport width
    top: 0;
    left: 0;

    @media only screen and (min-width: $screen-sm-min) {
      top: 50%;
      left: 50% !important; //override the nuts calc for mobile focus
      transform: translateX(-50%) translateY(-50%);
    }

    @media print {
      display: none;
    }
  }

  // video mobile focus adjustment
  &__background {
    // MOBILE video size calcs (trying to avoid JS)
    // Here's how we get there. We mix units so can't make them sass vars.
    // $bottom-border: .4rem;
    // $video-height: 100vh - $banner-height-mobile - $bottom-border;
    // $video-width: 1280 / 720 * $video-height; // aspect ratio of video
    // $width-diff: 100vw - $video-width;
    // $focus-adjustment: value between 0 and 1 representing percentage
    // $left-position: $focus-adjustment * $width-diff;

    @for $i from 0 through 10 {
      &--focus-#{$i * 10} {
        video {
          left: calc(#{$i * 0.1} * (100vw - (1280 / 720 * (100vh - #{$banner-height-mobile} - 0.4rem))));
        }
      }
    }
  }

  &__background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; // parallax overflow not necessary when at the top of the page
    background: {
      size: cover;
      position: 50%;
      color: $gray;
    }

    &--focus-right {
      background-position: 65%;

      video {
        left: calc(0.65 * (100vw - (1280 / 720 * (100vh - #{$banner-height-mobile} - 0.4rem))));
      }
    }

    &--focus-left {
      background-position: 35%;

      video {
        left: calc(0.35 * (100vw - (1280 / 720 * (100vh - #{$banner-height-mobile} - 0.4rem))));
      }
    }

    @for $i from 0 through 10 {
      &--focus-#{$i * 10} {
        background-position: #{$i * 10%};
      }
    }

    @media only screen and (min-width: $screen-sm-min) {
      background-position: 50%;
    }
  }

  &__slides {
    min-height: 40rem;
    height: 100%;
    overflow: hidden;

    @media only screen and (min-width: $screen-sm-min) {
      min-height: 50rem;
      max-height: 70.2rem;
      height: calc(100vh - #{$banner-height-desktop} - 0.4rem);
    }
  }

  &__slide {
    @extend %gradient-overlay;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 2.5rem 0 9rem;

    @media only screen and (min-width: $screen-sm-min) {
      padding: 6rem 0 11rem;
    }

    @for $i from 0 through 10 {
      &--gradient-#{10 * $i} {
        &:before {
          opacity: #{0.1 * $i};
        }
      }
    }

    .container {
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      height: 100%;
      z-index: 100;

      @media only screen and (min-width: $screen-sm-min) {
        justify-content: space-between;
      }
    }
  }

  &__overlay {
    width: calc(100% - 3rem);

    @media only screen and (min-width: $screen-sm-min) {
      width: 50%;
      max-width: 50rem;
    }

    &-title {
      @extend .h2;
      color: $white;
    }

    .button {
      @extend .button--white;

      margin: 2em 1rem 0 0;
      font-style: normal;
    }

    p {
      @extend .p1;
      color: $white;
      margin: 0;
    }
  }

  &__announcements {
    $max-announcement-width: 40rem;
    position: absolute;
    top: 0;
    padding: 2.4rem 0;
    width: 100%;
    z-index: 100;

    @media only screen and (min-width: $screen-sm-min) {
      top: auto;
      bottom: 4rem;
      right: $side-gutter-desktop--wide;
      width: $max-announcement-width;
      max-width: 40%;
      padding: 0;
    }

    @media only screen and (min-width: $screen-lg-min) {
      right: calc(#{$side-gutter-desktop--wide});
    }

    @media only screen and (min-width: (1440em/16)) {
      // align to the right edge of the slide container without using the actual container since that would block interactions with the slide below it
      left: calc(50% + #{$container-max-width} / 2 - #{$max-announcement-width} - #{$side-gutter-desktop--wide});
      right: auto;
    }

    .container {
      @media only screen and (min-width: $screen-sm-min) {
        padding: 0;
      }
    }
  }

  &__announcement {
    position: relative;
    display: none;
    margin-bottom: 1rem;
    color: $white;
    text-align: left;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 0.4rem;
    overflow: hidden;

    @media only screen and (min-width: $screen-sm-min) {
      padding: 2.5rem 3rem;
      margin-top: 1rem;
      margin-bottom: 0;
      background-image: none;
    }

    &--hide {
      animation: hide-announcement 250ms ease-out;
      animation-fill-mode: forwards;
    }

    &-close {
      display: none;
      width: 3.4rem;
      height: 3.4rem;
      position: absolute;
      top: 0;
      right: 0;
      -webkit-appearance: none;
      border: 0;
      background: {
        color: transparent;
        image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.4649 1.54697L1.53519 10.453' stroke='"+$white+"' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M10.4615 10.4564L1.53838 1.54365' stroke='"+$white+"' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
        position: 50%;
        repeat: no-repeat;
      }
      cursor: pointer;

      &:hover,
      &:focus {
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.4649 1.54697L1.53519 10.453' stroke='"+$orange+"' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M10.4615 10.4564L1.53838 1.54365' stroke='"+$orange+"' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
      }

      @media only screen and (min-width: $screen-sm-min) {
        display: block;
      }
    }

    &-title {
      @extend .h4;
      margin: 0;
      display: none;

      @media only screen and (min-width: $screen-sm-min) {
        display: block;
        margin-bottom: 1em;
      }
    }

    &-title-link {
      @extend .h4;
      margin: 0;

      a {
        display: block;
        position: relative;
        padding: 1.2rem 4.5rem 1.2rem 1.5rem;
        color: $white;
        text-decoration: none;
        background: {
          image: url("data:image/svg+xml,%3Csvg width='9' height='16' viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.79184 15L8 8.00042L1.79259 1L1.00037 1.8932L6.41669 8.00042L1 14.1072L1.79184 15Z' fill='"+$white+"' stroke='"+$white+"' stroke-width='0.5'/%3E%3C/svg%3E%0A");
          repeat: no-repeat;
          size: 0.7rem;
          position: right 1.5rem top 50%;
        }

        &:hover,
        &:focus {
          text-decoration: none;
          color: $orange;
          background-image: url("data:image/svg+xml,%3Csvg width='9' height='16' viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.79184 15L8 8.00042L1.79259 1L1.00037 1.8932L6.41669 8.00042L1 14.1072L1.79184 15Z' fill='"+$orange+"' stroke='"+$orange+"' stroke-width='0.5'/%3E%3C/svg%3E%0A");
        }
      }

      @media only screen and (min-width: $screen-sm-min) {
        display: none;
      }
    }

    p {
      @extend .p2;
      margin-bottom: 0;
      display: none;

      @media only screen and (min-width: $screen-sm-min) {
        display: block;
      }
    }

    .button {
      @extend .button;
      @extend .button--white;
      display: none;
      margin-top: 1.6rem;

      @media only screen and (min-width: $screen-sm-min) {
        display: inline-block;
      }
    }
  }

  .next-section-link {
    left: auto;
    right: $side-gutter-mobile--wide;
    z-index: 110;

    @media only screen and (min-width: $screen-sm-min) {
      left: calc(50% - 1rem);
      right: auto;
    }
  }

  .flickity-page-dots {
    @extend .container;
    margin: 0 auto;
    z-index: 90;

    padding-right: 6rem; // avoid overlapping the arrow
    bottom: 1.9rem;
    left: 0;
    text-align: left;
    counter-reset: gallery-dot;

    @media only screen and (min-width: $screen-sm-min) {
      bottom: 4rem;
      left: 50%;
      transform: translateX(-50%);
      padding-right: 52%;
    }

    @media only screen and (min-width: $screen-lg-min) {
      padding-right: 74rem;
    }

    .dot {
      position: relative;
      width: 4.7rem;
      max-width: calc(25% - 1rem);
      height: 2.3rem;
      margin: 0 1rem 0 0;
      opacity: 1;
      border-radius: 0;
      background-color: transparent;
      border-top: 0.2rem solid $gray;

      @media only screen and (min-width: $screen-sm-min) {
        width: 9rem;
        max-width: calc(25% - 1.8rem);
        margin: 0 1.8rem 0 0;
      }

      &.is-selected,
      &:hover,
      &:focus {
        &:before {
          color: $white;
        }
      }

      &.is-selected {
        &:after {
          content: '';
          position: absolute;
          top: -0.2rem;
          left: 0;
          display: block;
          width: 100%;
          height: 0.2rem;
          background-color: $white;
        }
      }

      &:before {
        @extend .p3;
        margin: 0;
        content: '0' counter(gallery-dot);
        counter-increment: gallery-dot;
        color: $white;
        position: absolute;
        left: 0;
        top: 0.5rem;
        transition: color 150ms;

        color: $gray;
      }
    }
  }

  .flickity-prev-next-button {
    display: none;
    z-index: 2;

    @media only screen and (min-width: $screen-sm-min) {
      display: block;
      height: 100%;
      top: 0;
      width: $side-gutter-desktop--wide;
      width: calc((100% - #{$container-max-width}) / 2 + #{$side-gutter-desktop--wide});
      min-width: $side-gutter-desktop--wide;
      border-radius: 0;
      transform: none;
      padding: 0;
      opacity: 0;
    }

    svg {
      display: none;
    }

    &.next {
      right: 0;
    }

    &.previous {
      left: 0;
    }
  }

  /* crossfade */
  .flickity-slider {
    transform: none !important;

    .module-hero-gallery__slide {
      left: 0 !important;
      opacity: 0;
      transition: opacity 0.1s;
      transition-delay: 1s;
      z-index: 0;

      &.is-selected {
        opacity: 1;
        z-index: 1;
        transition: opacity 1s ease-out;
      }
    }
  }
}
