//  Function to create an optimized svg url
//  http://codepen.io/jakob-e/pen/doMoML
@function svg-url($svg) {
  //  Chunk up string in order to avoid "stack level too deep" error
  $encoded: '';
  $slice: 2000;
  $index: 0;
  $loops: ceil(str-length($svg)/$slice);
  @for $i from 1 through $loops {
    $chunk: str-slice($svg, $index, $index + $slice - 1);
    //
    //   Encode (may need a few extra replacements)
    //
    $chunk: str-replace($chunk, '"', "'");
    $chunk: str-replace($chunk, '<', '%3C');
    $chunk: str-replace($chunk, '>', '%3E');
    $chunk: str-replace($chunk, '&', '%26');
    $chunk: str-replace($chunk, '#', '%23');
    $encoded: #{$encoded}#{$chunk};
    $index: $index + $slice;
  }
  @return url('data:image/svg+xml;charset=utf8,#{$encoded}');
}

//  Helper function to replace characters in a string
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index +
      str-length($search)), $search, $replace);
  }
  @return $string;
}

// fluidly resize type
@mixin fluid-type($font-min, $font-max, $screen-min, $screen-max) {
  font-size: #{$font-min}px;

  @media only screen and (min-width: #{$screen-min}px) {
    font-size: calc(
      #{$font-min}px + #{($font-max - $font-min)} * (100vw - #{$screen-min}px) / (#{$screen-max} - #{$screen-min})
    );
  }

  @media only screen and (min-width: #{$screen-max}px) {
    font-size: #{$font-max}px;
  }
}

// Creates a simple grid to fill the available space given a number of columsn and the gap size
@mixin simple-grid($num-cols, $grid-gap) {
  $num-gaps: $num-cols - 1;
  $col-width-no-gap: 100% / $num-cols;
  $grid-template: null;

  @for $i from 1 through $num-cols {
    $grid-template: append($grid-template, calc(#{$col-width-no-gap} - #{$grid-gap} * #{$num-gaps} / #{$num-cols}));
  }

  display: grid;
  grid-template-columns: $grid-template;
  grid-gap: $grid-gap;
}


@mixin popup {
  display: none;
  background: rgba(0, 0, 0, 0.5);
  color: $white;
  width: 100%;
  max-width: 123rem;
  padding: 2rem;

  @media only screen and (min-width: $screen-sm-min) {
    padding: 6rem 4rem;
  }

  @media only screen and (min-width: $screen-md-min) {
    padding: 8rem;
  }

  .fancybox-button {
    width: 4rem;
    height: 4rem;
    padding: 0;
    top: 1.5rem;
    right: 1.5rem;
  }
}
