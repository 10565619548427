%form-label {
  @extend .h4;
  display: block;
  margin-bottom: 0.8em;
}

%form-section-title {
  @extend .h3;
  margin: 0;
}

%form-left-padding {
  margin-left: 3.5rem;
}

%form-input {
  @extend .p2;
  font-family: $font-family-sans-serif;
  -webkit-appearance: none;
  margin: 0;
  padding: 0.5rem 1rem;
  background: $white;
  color: $black;
  border: 1px solid $black;
  border-radius: 0.4rem;
  line-height: normal;
  display: block;
  width: 100%;
  height: 3.5rem;
  box-shadow: none;
  transition: all 0.3s ease-out;

  &:focus {
    outline: none;
  }
}

%form-select {
  height: 3.5rem;
  -webkit-appearance: none;
  margin: 0;
  padding: 0.5rem 3rem 0.5rem 1rem;
  background: #fff;
  color: $black;
  border: 1px solid $black;
  border-radius: 0.4rem;
  display: block;
  width: 100%;
  box-shadow: none;
  transition: all 0.3s ease-in-out;
  background: {
    image: url("data:image/svg+xml,%3Csvg width='7' height='11' viewBox='0 0 7 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.5 0L0 4.30435H7L3.5 0ZM3.5 11L7 6.69565H0L3.5 11Z' fill='black'/%3E%3C/svg%3E%0A");
    position: right 1rem top 50%;
    size: 0.7rem;
    repeat: no-repeat;
  }

  line-height: normal;
  font: {
    family: $font-family-sans-serif;
    weight: 400;
    style: normal;
    size: 1.6rem;
  }
  letter-spacing: 0.01rem;
}

/**
 * Global
 */
input {
	color: $black;
}

/**
 * Service form
 */
.service-form {
  @media only screen and (min-width: $screen-sm-min) {
    display: flex;
  }

  &__tags,
  &__cats {
    margin-bottom: 2rem;
    padding-right: 4rem;

    @media only screen and (min-width: $screen-sm-min) {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
      width: auto;
    }
  }

  select {
    @extend %form-select;
    width: auto;
    min-width: 20rem;
  }

  label {
    @extend %form-label;
  }
}

/* Search form */
.search-form {
  margin: 2rem 0;

  input[type='search'] {
    margin-bottom: 1rem;
  }
}

.search-form label {
}
.search-form .search-field {
  @extend %form-input;
  display: inline-block;
  width: 20rem;
  margin-right: 2rem;
}
.search-form .search-submit {
  @extend .button;
}
