/* animations */
@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes chevron-blink {
  0%,
  10% {
    border-color: rgba($white, 0.5);
  }

  5% {
    border-color: rgba($white, 1);
  }
}

@keyframes chevron-blink-dark {
  0%,
  10% {
    border-color: rgba($black, 0.3);
  }

  5% {
    border-color: rgba($black, 1);
  }
}

@keyframes slidedown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes hide-announcement {
  from {
    opacity: 1;
    max-height: 30rem;
  }
  to {
    opacity: 0;
    max-height: 0rem;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
  }
}
