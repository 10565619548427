/**
 * New GF overrides
 */
body .gform-theme.gform-theme--framework.gform_wrapper {
	--gf-label-space-x-secondary: 0 !important;
	--gf-ctrl-label-font-size-secondary: 1.6rem !important;
	--gf-font-size-primary: 1.6rem !important;
	--gf-font-size-secondary: 1.6rem !important;
	--gf-font-size-tertiary: 1.6rem !important;
	--gf-ctrl-readonly-font-weight: 400 !important;
	--gf-field-section-border-width: 0 !important;
	--gf-ctrl-label-font-weight-primary: 900 !important;
	--gf-ctrl-label-font-size-primary: 1.6rem !important;

	// hide required field message
	.gform_required_legend {
		display: none;
	}

	// typography
	.gfield {
		@extend .p1;
		margin: 0;
	}

	label,
	.gfield_creditcard_warning_message {
		@extend .p2;
		margin: 0;
	}

	.gfield_label {
		@extend .h4;
		margin-bottom: 0.5em;
	}

	.gfield_description {
		&,
		p {
			margin-top: 0;
			margin-bottom: 0.8em;
		}
	}

	.gfield.gf-subtotal {
		display: flex;
		justify-content: space-between;
		border-top: 0.3rem solid $black;
		padding: 1.4rem 0;
		margin: 0;

		& + .gf-subtotal {
			border-top-width: 0.1rem;
		}

		.gfield_label {
			flex-grow: 1;
			flex-shrink: 1;
			flex-basis: auto;
			width: auto;
			padding-right: 1.5rem;
			margin: 0;
			line-height: 1.2;
			display: flex;
			align-items: center;
		}

		.ginput_container {
			flex-grow: 0;
			flex-shrink: 0;
			flex-basis: auto;
			width: 10rem;
			margin: 0;
			text-align: right;

			input.small,
			.ginput_product_price {
				@extend .p2;
				margin: 0;
				border: 0;
				box-shadow: none;
				background: transparent;
				padding: 0;
				text-align: right;
				width: 100%;
			}
		}
	}

	.gfield_total {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 2rem;
		align-items: center;
		border-top: 0.3rem solid $black;
		border-bottom: 0.1rem solid $black;
		padding: 2rem 0;

		label.gfield_label,
		.ginput_total {
			@extend .h3;
			margin: 0;
			text-transform: uppercase;
			padding: 0;
		}

		.ginput_container_total {
			padding: 0;
		}

		.ginput_total {
			border: 0;
			padding: 0;
			text-align: right;
		}
	}

	.gf-order-table {
		.gfield_description {
			p {
				@extend .p2;
			}
		}

		.gfield_radio {
			> li {
				padding: 1.2rem 0;
				border-top: 0.1rem solid $black;
			}

			label {
				position: relative;
				width: 100%;
				margin: 0;

				&:before {
					position: absolute;
					top: 0;
					left: 0;
					background-position: 50% top;
				}
			}

			input:checked + label .td {
				color: $orange;
			}
		}

		.tr {
			display: flex;
			width: 100%;
			padding-left: 3.5rem;
		}

		.td,
		.th {
			line-height: 1;
			margin: 0;
			flex: 1;
			padding-right: 0.5rem;

			&:last-child {
				padding-right: 0;
			}

			&:nth-child(1),
			&:nth-child(6) {
				flex: 3;
			}
		}

		.th {
			font-weight: 600;
		}

		.gf_inline_price {
			display: none;
		}

		.gfield_radio li {
			display: flex;
		}
	}

	.ginput_product_price_label {
		display: none;
	}

	input[type="text"],
	input[type="search"],
	input[type="password"],
	input[type="number"],
	input[type="email"],
	input[type="url"],
	input[type="tel"],
	input[type="file"],
	textarea {
		--gf-local-border-color: $black !important;
		@extend %form-input;
	}

	// Layout
	.form-left-padding {
		@extend %form-left-padding;
	}

	fieldset {
		margin-left: 0;
		margin-right: 0;
		padding: 0;
		border: 0;
	}

	.gfield_html,
	.gfield_description {
		h2 {
			@extend %form-section-title;
		}

		p {
			@extend .p2;
		}

		ul {
			margin: 0 0 1em;
			list-style: disc;
		}
	}

	.gfield:last-child {
		margin-bottom: 0;
	}

	.gfield_checkbox,
	.gfield_radio {
		list-style: none;
		margin: 0;
		padding: 0;

		br {
			display: none;
		}

		input {
			position: absolute;
			opacity: 0;

			&:focus + label {
				color: $orange;
			}

			+ label:before {
				content: "";
				display: inline-block;
				vertical-align: text-bottom;
				width: 2rem;
				height: 2rem;
				margin-right: 1.5rem;
				background: {
					repeat: no-repeat;
					position: 50%;
				}
			}
		}
	}

	.gfield_radio {
		input + label:before {
			background-size: 1.4rem;
			background-image: svg-url(
				'<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" stroke="black"/></svg>'
			);
		}

		input:checked + label:before {
			background-image: svg-url(
				'<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" stroke="' +
					$orange +
					'"/><path fill-rule="evenodd" clip-rule="evenodd" d="M11 18C14.866 18 18 14.866 18 11C18 7.13401 14.866 4 11 4C7.13401 4 4 7.13401 4 11C4 14.866 7.13401 18 11 18Z" fill="' +
					$orange + '"/></svg>'
			);
		}

		input:checked + label {
			color: $orange;
		}
	}

	.gfield_checkbox {
		input + label:before {
			background-size: 1.4rem;
			background-image: svg-url(
				'<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="19" height="19" rx="1.5" stroke="black"/></svg>'
			);
		}

		input:checked + label:before {
			background-image: svg-url(
				'<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="19" height="19" rx="1.5" fill="' +
					$orange + '" stroke="' + $orange +
					'"/><path d="M4.84914 8.58622L3 10.5599L7.92502 15L16 5.74281L13.9237 4L7.68949 11.1469L4.84914 8.58622Z" fill="white"/></svg>'
			);
		}
	}

	.gfield_radio {
		li {
			margin-bottom: 0.5rem;
		}

		input[type="text"] {
			width: auto;
			display: inline-block;
		}
	}

	.gfield_consent_label {
		margin-left: 1rem;
	}

	.ginput_cardextras {
		margin-left: 0;
	}

	.ginput_cardinfo_left {
		padding-right: 1rem;
	}

	.ginput_cardinfo_right {
		display: grid;
		gap: 0 1rem;
		grid-template-columns: 1fr auto;
		padding: 0;
	}

	/* Validation */
	.gform_validation_errors {
		@extend .p1;
		color: $orange;
	}

	.gfield_error {
		padding: $form-space;
		border: 0 solid #eee;
		border-left-width: 5px;
		border-left-color: #d9534f;
		background: hsl(2, 64, 98);

		> label,
		> legend,
		.validation_message {
			color: $input-error;
		}

		> legend {
			position: relative;
			top: 2rem;
			margin-bottom: 1.5rem;
		}

		input,
		textarea,
		select {
			border-color: $input-error;

			&:focus {
				border-color: darken($input-error, 10%);
			}
		}
	}
}

// submit button
.gform-theme.gform-theme--framework.gform_wrapper
	input[type="submit"].button.gform_button:where(
		:not(.gform-theme-no-framework):not(.gform-theme__disable):not(
				.gform-theme__disable *
			):not(.gform-theme__disable-framework):not(
				.gform-theme__disable-framework *
			)
	) {
	@extend .button;
	font-size: 12px;
	line-height: 1;
}

/* Multi-column */

// override default grid
.gform-theme--foundation .gform_fields:has(.gfmc-container) {
	display: block;
}

.gfmc-container {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-template-rows: repeat(auto-fill, auto);
	inline-size: 100%;
	column-gap: var(--gf-form-gap-x);
	row-gap: var(--gf-form-gap-y);
}

.gfmc-column {
	--gf-form-gap-y: 3rem;

	align-self: start;
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-template-rows: repeat(auto-fill, auto);
	inline-size: 100%;
	column-gap: var(--gf-form-gap-x);
	row-gap: var(--gf-form-gap-y);

	@media (min-width: 1000px) {
		column-gap: var(--gf-form-gap-x);
	}

	&:nth-child(1) {
		@media only screen and (min-width: $screen-sm3-min) {
			grid-column: 1 / span 7;
		}
	}

	&:nth-child(2) {
		@media only screen and (min-width: $screen-sm3-min) {
			grid-column: 9 / span 4;
			position: sticky;
			top: 0;
			transition: top 400ms; // same transition time as header
			top: calc(#{$banner-height-desktop} + 2rem);

			body.menu-hidden & {
				top: 2rem;
			}
		}
	}
}

body .gfmc-field div:not(.gchoice, .gfield_total, .gf-subtotal) {
	padding-bottom: 0;
}
