.comment-list {

}
.comment-list ol {
  list-style: none;
}
.comment-form p {

}
.comment-form input[type="text"],
.comment-form input[type="email"],
.comment-form input[type="url"],
.comment-form textarea {

}
.comment-form input[type="submit"] {

}
