.module-buttons-list {
  position: relative;
  z-index: 10;
  margin: 0 0 7.5rem;
  text-align: center;

  @media only screen and (min-width: $screen-sm-min) {
    margin: 7.5rem 0 12rem;
  }

  .button {
    @extend .button--large;
    width: 100%;
    max-width: 32.5rem;
    margin: 0.5rem 1rem;
  }
}
