.button {
  -webkit-appearance: none;
  display: inline-block;
  padding: 0.8rem 1rem;
  border: 0.2rem solid $black;
  border-radius: 4rem; // always round!
  transition: all 150ms;
  background: transparent;

  color: $black;
  font: {
    family: $font-family-sans-serif;
    weight: 700;
    size: 1.2rem;
  }
  letter-spacing: 0.1rem;
  line-height: 1;
  text: {
    transform: uppercase;
    decoration: none;
    align: center;
  }

  @media only screen and (min-width: $screen-sm-min) {
    padding: 0.8rem 3rem;
  }

  &:hover,
  &:focus {
    outline: none !important;
    color: $white;
    background-color: $orange;
    border-color: $orange;
    text-decoration: none;
    cursor: pointer;
  }

  &--white {
    color: $white;
    border-color: $white;
    background: transparent;

    &:hover,
    &:focus {
      color: $white;
      background-color: $orange;
      border-color: $orange;
    }
  }

  &--white-on-orange {
    color: $white;
    border-color: $white;
    background: transparent;

    &:hover,
    &:focus {
      color: $orange;
      background-color: $white;
      border-color: $white;
    }
  }

  &--black {
    background-color: $black;
    color: $white;
  }

  &--large {
    padding: 1.4rem 3rem;
    font: {
      weight: 900;
      style: italic;
      size: 2rem;
    }
    letter-spacing: 0.1rem;
    text-transform: uppercase;
  }
}

.next-section-link {
  $animation-duration: 5s;

  position: absolute;
  bottom: 4rem;
  left: calc(50% - 1rem);
  z-index: 100;
  transform-origin: 50% 50%;
  transform: rotate(45deg);
  animation-name: chevron-blink;
  animation-delay: 200ms;

  @media only screen and (min-width: $screen-sm-min) {
    bottom: 6.1rem;
  }

  &,
  &:before,
  &:after {
    box-sizing: border-box;
    width: 1.4rem;
    height: 1.4rem;
    border: 0.2rem solid rgba($white, 0.5);
    border-top-width: 0;
    border-left-width: 0;
    animation-duration: $animation-duration;
    animation-iteration-count: infinite;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
  }

  &:before {
    transform: translateX(-0.7rem) translateY(-0.7rem);
    animation-name: chevron-blink;
    animation-delay: 0;
  }

  &:after {
    transform: translateX(0.7rem) translateY(0.7rem);
    animation-name: chevron-blink;
    animation-delay: 400ms;
  }
}

.print-button {
  display: inline-block;
  width: 2.9rem;
  height: 2.9rem;
  background: {
    position: 50%;
    size: 2.4rem;
    repeat: no-repeat;
    image: url("data:image/svg+xml,%0A%3Csvg aria-hidden='true' data-prefix='fal' data-icon='print' class='svg-inline--fa fa-print fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='"+darken(
        $white,
        30
      )+"' d='M432 192h-16v-82.75c0-8.49-3.37-16.62-9.37-22.63L329.37 9.37c-6-6-14.14-9.37-22.63-9.37H126.48C109.64 0 96 14.33 96 32v160H80c-44.18 0-80 35.82-80 80v96c0 8.84 7.16 16 16 16h80v112c0 8.84 7.16 16 16 16h288c8.84 0 16-7.16 16-16V384h80c8.84 0 16-7.16 16-16v-96c0-44.18-35.82-80-80-80zM320 45.25L370.75 96H320V45.25zM128.12 32H288v64c0 17.67 14.33 32 32 32h64v64H128.02l.1-160zM384 480H128v-96h256v96zm96-128H32v-80c0-26.47 21.53-48 48-48h352c26.47 0 48 21.53 48 48v80zm-80-88c-13.25 0-24 10.74-24 24 0 13.25 10.75 24 24 24s24-10.75 24-24c0-13.26-10.75-24-24-24z'%3E%3C/path%3E%3C/svg%3E");
  }

  &:hover,
  &:focus {
    background: {
      image: url("data:image/svg+xml,%0A%3Csvg aria-hidden='true' data-prefix='fal' data-icon='print' class='svg-inline--fa fa-print fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='"+$orange+"' d='M432 192h-16v-82.75c0-8.49-3.37-16.62-9.37-22.63L329.37 9.37c-6-6-14.14-9.37-22.63-9.37H126.48C109.64 0 96 14.33 96 32v160H80c-44.18 0-80 35.82-80 80v96c0 8.84 7.16 16 16 16h80v112c0 8.84 7.16 16 16 16h288c8.84 0 16-7.16 16-16V384h80c8.84 0 16-7.16 16-16v-96c0-44.18-35.82-80-80-80zM320 45.25L370.75 96H320V45.25zM128.12 32H288v64c0 17.67 14.33 32 32 32h64v64H128.02l.1-160zM384 480H128v-96h256v96zm96-128H32v-80c0-26.47 21.53-48 48-48h352c26.47 0 48 21.53 48 48v80zm-80-88c-13.25 0-24 10.74-24 24 0 13.25 10.75 24 24 24s24-10.75 24-24c0-13.26-10.75-24-24-24z'%3E%3C/path%3E%3C/svg%3E");
    }
  }
}

.play-button {
  display: block;
  width: 8rem;
  height: 8rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: background 250ms;
  background: {
    repeat: no-repeat;
    position: 50%;
    size: 8rem;
    image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 110 110'%3E%3Ccircle cx='55' cy='55' r='55' style='fill:%23FFF;opacity:1'/%3E%3Ccircle cx='55' cy='55' r='41.7'/%3E%3Cpolygon points='67.3 54.5 47.6 65.3 47.6 43.7 ' fill='%23FFF'/%3E%3C/svg%3E");
  }

  @media only screen and (min-width: $screen-sm-min) {
    width: 11.2rem;
    height: 11.2rem;
    background-size: 11.2rem;
  }

  &:hover,
  &:focus {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 110 110'%3E%3Ccircle cx='55' cy='55' r='55' style='fill:%23FFF;opacity:1'/%3E%3Ccircle fill='"+$orange+"' cx='55' cy='55' r='41.7'/%3E%3Cpolygon points='67.3 54.5 47.6 65.3 47.6 43.7 ' fill='%23FFF'/%3E%3C/svg%3E");
  }
}

.back-button {
  @extend .p2;
  margin: 0;
  position: relative;
  font-weight: 600;
  color: $black;
  text-transform: uppercase;
  text-decoration: none;

  b {
    $animation-duration: 5s;

    position: absolute;
    top: 0.5rem;
    left: -1.9rem;
    transform-origin: 50% 50%;
    transform: scale(0.5) rotate(135deg);
    animation-delay: 200ms;

    @media only screen and (min-width: $screen-sm-min) {
      left: -4rem;
      transform: rotate(135deg);
    }

    &,
    &:before,
    &:after {
      box-sizing: border-box;
      width: 1.4rem;
      height: 1.4rem;
      border: 0.2rem solid rgba($black, 0.3);
      border-top-width: 0;
      border-left-width: 0;
      animation-name: chevron-blink-dark;
      animation-duration: $animation-duration;
      animation-iteration-count: infinite;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
    }

    &:before {
      transform: translateX(-0.7rem) translateY(-0.7rem);
      animation-delay: 0;
    }

    &:after {
      transform: translateX(0.7rem) translateY(0.7rem);
      animation-delay: 400ms;
    }
  }
}

/* wp-pagenavi post nav */
.wp-pagenavi {
	display: flex;
	flex-wrap: wrap;
  margin-bottom: 4rem;

  @media only screen and (min-width: $screen-sm-min) {
    margin-bottom: 0;
  }

  span,
  a {
    @extend .p3;
    margin: 0;
    border: 0;
    padding: 0.25em 0.75em;
  }

  a {
    color: $black;

    &:hover,
    &:focus {
      color: $orange;
    }
  }

  span.pages {
    padding-left: 0;
  }
}

.download-button-wrapper {
  margin: 0 0 1em;
}
