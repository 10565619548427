.module-home-blocks {
  position: relative;
  z-index: 10;
  overflow: hidden;
  padding: 2.5rem 0;
  background: linear-gradient(180deg, #383737 4.64%, #5d5d5d 100%);

  @media only screen and (min-width: $screen-sm-min) {
    padding: 4rem 0 5rem;
  }

  .container {
    @extend .container--wide;

    @media only screen and (min-width: $screen-sm-min) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__background {
    position: absolute;
    z-index: 0;
    left: 0;
    top: -25%;
    width: 100%;
    height: 150%; // allow for parallax overflow

    background: {
      image: repeating-linear-gradient(
        135deg,
        transparent,
        transparent 0.7rem,
        rgba(72, 72, 72, 1) 0.7rem,
        rgba(72, 72, 72, 1) 1.4rem
      );
    }
  }

  &__rvs,
  &__newsletter {
    padding: 3.5rem 2.5rem;
    text-align: center;
    position: relative;
    z-index: 2;

    @media only screen and (min-width: $screen-sm-min) {
      padding: 3rem;
      text-align: left;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
      width: 48.4%;
    }

    p {
      @extend .p1;
      margin-bottom: 3rem;
    }
  }

  &__rvs {
    background-color: $black;
    color: $white;
    margin-bottom: 2.5rem;

    @media only screen and (min-width: $screen-sm-min) {
      margin-bottom: 0;
    }

    @media only screen and (min-width: $screen-md-min) {
      margin-bottom: 0;
      display: flex;
      flex-direction: row-reverse;
    }

    .button {
      @extend .button--white;
    }

    &-counter {
      @media only screen and (min-width: $screen-sm-min) {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: auto;
        width: 17rem;
      }

      a {
        position: relative;
        z-index: 10;
        display: block;
        width: 100%;
        height: 100%;
        color: $white;

        &:hover,
        &:focus {
          color: $white;
          text-decoration: none;
        }
      }
    }

    &-text {
      max-width: 30rem;
      margin: 0 auto;

      @media only screen and (min-width: $screen-sm-min) {
        padding-right: 10%;
        margin: 0;
        max-width: none;
      }
      @media only screen and (min-width: $screen-lg-min) {
        padding-right: 20%;
      }
    }
  }

  &__newsletter {
    background-color: $orange-2;
    color: $white;

    @media only screen and (min-width: $screen-md-min) {
      display: flex;
    }
  }

  &__newsletter-text {
    max-width: 30rem;
    margin: 0 auto;

    @media only screen and (min-width: $screen-sm-min) {
      padding-right: 10%;
      margin: 0;
      max-width: none;
    }
    @media only screen and (min-width: $screen-lg-min) {
      padding-right: 20%;
    }
  }

  &__newsletter-form {
    max-width: 30rem;
    margin: 0 auto;

    @media only screen and (min-width: $screen-sm-min) {
      margin: 0;
      max-width: none;
    }

    @media only screen and (min-width: $screen-md-min) {
      width: 20rem;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
    }

    @media only screen and (min-width: $screen-lg-min) {
      width: 25rem;
    }
  }
}

.rvs-counter {
  box-sizing: content-box;
  position: relative;
  width: 16rem;
  height: 16rem;
  margin: 0 auto 2rem;
  border: 0.3rem solid $white;
  border-radius: 50%;
  text-align: center;

  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0.3rem;
    left: 0.3rem;
    width: 14.8rem;
    height: 14.8rem;
    margin: 0 auto 2rem;
    border: 0.3rem solid $white;
    border-radius: 50%;
    z-index: 0;
  }

  &__title {
    font: {
      family: $font-family-sans-serif-extended;
      size: 2.1rem;
      weight: 900;
    }
    line-height: 1;
    padding: 3.5rem 0 2rem;
  }

  &__text {
    font: {
      family: $font-family-sans-serif-extended;
      size: 1rem;
      weight: 900;
    }
    line-height: 1;
    text-transform: uppercase;
  }

  &__numbers {
    border: 0.2rem solid $white;
    margin: 0 auto 0.8rem;
    width: 10.2rem;
    height: 2.7rem;
    display: flex;
    justify-content: space-between;

    b {
      display: block;
      width: (100% / 6);
      font: {
        family: $font-family-numbers;
        size: 2.2rem;
        weight: 500;
      }
      line-height: 1.45;
      border-right: 0.1rem solid $white;
    }
  }
}

.mc-signup {
  height: 100%;

  form {
    @media only screen and (min-width: $screen-sm-min) {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &__fields {
    margin-bottom: 2rem;

    @media only screen and (min-width: $screen-sm-min) {
      margin-bottom: 0;
    }
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $white;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: $white;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: $white;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: $white;
  }

  input[type='text'],
  input[type='email'] {
    @extend .p2;
    margin-bottom: 0.8rem;
    width: 100%;
    padding: 0.6rem 1.2rem;
    -webkit-appearance: none;
    border: 0.1rem solid $white;
    border-radius: 0.2rem;
    background-color: rgba(255, 255, 255, 0.2);
    color: $white;
  }

  &__button {
    margin-top: 2rem;
  }

  .button {
    @extend .button--white-on-orange;
    cursor: pointer;
  }
}
