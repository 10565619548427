/*
wide: 285 x 195
wide-large: 590 x 410
tall: 285 x 410
*/

.module-image-gallery {
  $grid-cell-width: (285 / 1200 * 100%);
  $grid-gutter-width: (20 / 1200 * 100%);
  position: relative;
  z-index: 10;
  display: none;

  @media only screen and (min-width: $screen-sm-min) {
    display: block;
  }

  .conainer {
    &:after {
      content: '';
      clear: both;
      display: table;
    }
  }

  &__gutter-sizer {
    // required by Isotope
    width: $grid-gutter-width;
  }

  &__grid-sizer {
    // required by Isotope
    width: $grid-cell-width;
  }

  &__wrapper {
    width: $grid-cell-width;
    overflow: hidden;
    float: left;
    margin-bottom: $grid-gutter-width;
    transform: scale(1);
    transition: all 350ms ease-out;
    box-shadow: none;

    &--wide-large {
      width: (50% - $grid-gutter-width/2);

      .module-image-gallery__link:before {
        padding-top: (410 / 590 * 100%);
      }
    }

    &--tall {
      // adjust the height
      .module-image-gallery__link:before {
        padding-top: (410 / 285 * 100%);
      }
    }

    &:hover,
    &:focus {
      transform: scale(1.008);
      box-shadow: 0.25rem 0.25rem 1.2rem rgba(0, 0, 0, 0.45);
    }
  }

  &__link {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;

    background: {
      size: cover;
      position: 50%;
    }

    &:before {
      // enforces aspect ratio
      content: '';
      display: block;
      padding-top: (195 / 285 * 100%);
      position: relative;
      width: 0.1rem;
      height: 0;
    }
  }
}
