.store-choice-popup {
	@include popup;
	text-align: center;
	padding-left: 0;
	padding-right: 0;

	&__title {
		@extend .h2;
		margin: 0 0 1em;
	}

	&__buttons {
		margin: 0 0 2em;
	}

	&__button {
		@extend .button;
		@extend .button--white;
		width: 100%;
		max-width: 29rem;
		margin: 0 1em 1em;

		@media only screen and (min-width:$screen-sm-min) {
			width: 29rem;
		}
	}

	&__checkbox {
		vertical-align: text-top;
	}

	&__label {
		font: {
			family: $font-family-sans-serif;
			weight: 700;
			size: 1.2rem;
		}
		letter-spacing: 0.1rem;
		line-height: 1;
		text: {
			transform: uppercase;
			decoration: none;
			align: center;
		}
		vertical-align: middle;
	}
}
