.service-docs-header {
	display: grid;
	align-items: center;
	gap: 2rem;
	margin-bottom: 4rem;

	@media only screen and (min-width: $screen-sm-min) {
		grid-template-columns: 1fr auto;
	}
}

.service-results {
	position: relative;
	z-index: 10;
	@extend %standard-type;

	p {
		margin-bottom: 1em;
	}

	&__download {
		justify-content: end;
	}

	&__group {
		margin-bottom: 6rem;

		&-title {
			@extend .h2;
			position: sticky;
			top: 0;
			background: white;
			margin-bottom: 0 !important;

			@media only screen and (min-width: $screen-sm3-min),
				print and (min-width: $screen-sm3-min) {
				position: relative;
			}
		}

		&-header {
			display: grid;
			grid-template-columns: 1fr auto;
			align-items: center;
			gap: 2rem;
			margin-bottom: 2rem;
		}
	}

	&__table {
		width: 100%;

		@media only screen and (min-width: $screen-sm3-min),
			print and (min-width: $screen-sm3-min) {
			display: table;
		}
	}

	&__row {
		border-bottom: 0.1rem solid $black;
		padding: 1rem 0;

		@media only screen and (min-width: $screen-sm3-min),
			print and (min-width: $screen-sm3-min) {
			border-bottom: 0;
			padding: 0;
			display: table-row;
		}

		&--header {
			border-bottom: 0.4rem solid $black;

			@media only screen and (min-width: $screen-sm3-min),
				print and (min-width: $screen-sm3-min) {
				border: 0;
			}

			.service-results__cell {
				display: none;
				@extend .h4;
				padding-bottom: 2rem;
				border-bottom: 0.4rem solid $black;
				-webkit-hyphens: manual;
				hyphens: manual;

				@media only screen and (min-width: $screen-sm3-min),
					print and (min-width: $screen-sm3-min) {
					display: table-cell;
				}
			}
		}

		&--result {
			.service-results__cell {
				@media only screen and (min-width: $screen-min),
					print and (min-width: $screen-sm3-min) {
					display: flex;
				}

				@media only screen and (min-width: $screen-sm3-min),
					print and (min-width: $screen-sm3-min) {
					display: table-cell;
				}

				&:nth-child(1) {
					font-weight: 600;
				}

				.service-results__cell-label {
					@extend .h4;
					margin: 0;
					color: $black;
					-webkit-hyphens: manual;
					hyphens: manual;

					@media only screen and (min-width: $screen-min),
						print and (min-width: $screen-sm3-min) {
						flex-grow: 0;
						flex-shrink: 0;
						flex-basis: auto;
						width: 13rem;
						line-height: 1.6;
					}

					@media only screen and (min-width: $screen-sm3-min),
						print and (min-width: $screen-sm3-min) {
						display: none;
					}
				}

				.service-results__cell-value {
					margin: 0 0 0.5em;

					@media only screen and (min-width: $screen-min),
						print and (min-width: $screen-sm3-min) {
						flex-grow: 1;
						flex-shrink: 1;
						flex-basis: auto;
						width: auto;
						margin: 0;
					}

					@media only screen and (min-width: $screen-sm3-min),
						print and (min-width: $screen-sm3-min) {
						width: auto;
					}
				}

				&:last-child {
					.service-results__cell-value {
						margin-top: 0.4em;
						margin-bottom: 0;

						@media only screen and (min-width: $screen-sm3-min),
							print and (min-width: $screen-sm3-min) {
							margin: 0;
						}
					}
				}
			}
		}
	}

	&__cell {
		@extend .p2;
		margin: 0.25rem 0;

		@media only screen and (min-width: $screen-sm3-min),
			print and (min-width: $screen-sm3-min) {
			display: table-cell;
			margin: 0;
			padding: 1rem 1rem 1rem 0;
			border-bottom: 0.1rem solid $black;

			&:last-child {
				padding-right: 0;
			}

			&:nth-child(1),
			&:nth-child(2) {
				width: 13.333%;
			}

			&:nth-child(4),
			&:nth-child(3) {
				width: 25%;
			}

			&:last-child {
				// view button
				width: 10%;
			}
		}

		@media only print {
			&:last-child {
				display: none;
			}
		}

		.button {
			margin-left: 0;
			margin-right: 0;

			@media only screen and (min-width: $screen-sm3-min) {
				width: 100%;
			}
		}
	}
}
