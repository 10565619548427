// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}
.aligncenter {
  display: block;
  height: auto;
  margin: 0 auto;
}
.alignleft,
.alignright {
  display: block;
  height: auto;
  margin-bottom: 0.25rem;
}

@media only screen and (min-width: $screen-sm-min) {
  // Only float if not on an extra small device
  .alignleft {
    float: left;
    margin-right: 1rem;
  }

  .alignright {
    float: right;
    margin-left: 1rem;
  }
}

// Captions
.wp-caption {
}
.wp-caption > img {
}
.wp-caption-text {
}
.wp-caption-text {
}

// Text meant only for screen readers
.screen-reader-text {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
	color: $black;
}
