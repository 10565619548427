/* Type */
.h1 {
  margin: 0 0 0.5em;
  font: {
    weight: 900;
    style: italic;
    size: 4.4rem;
  }
  line-height: 1;
  letter-spacing: 0.1rem;
  text-transform: uppercase;

  @media only screen and (min-width: $screen-sm-min) {
    font-size: 5rem;
  }

  @media only screen and (min-width: $screen-md-min) {
    font-size: 6.5rem;
  }
}

.h2 {
  margin: 0 0 0.5em;
  font: {
    weight: 700;
    style: italic;
    size: 2.5rem;
  }
  line-height: 1.1;
  letter-spacing: 0.1rem;
  text-transform: none;

  @media only screen and (min-width: $screen-sm-min) {
    font-size: 3.5rem;
  }
}

.h3 {
  margin: 0 0 0.4em;
  font: {
    weight: 700;
    style: normal;
    size: 2rem;
  }
  line-height: 1;
  letter-spacing: 0.1rem;
  text-transform: none;

  @media only screen and (min-width: $screen-sm-min) {
    font-size: 2.5rem;
  }
}

.h4 {
  margin: 0 0 1em;
  font: {
    weight: 900;
    size: 1.4rem;
  }
  line-height: 1;
  letter-spacing: 0.1rem;
  text-transform: uppercase;

  @media only screen and (min-width: $screen-sm-min) {
    font-size: 1.6rem;
  }
}

.p1 {
  margin: 0 0 1.2em;
  font: {
    weight: 400;
    style: normal;
    size: 2rem;
  }
  line-height: 1.5;
  letter-spacing: 0.01rem;
}

.p2 {
  margin: 0 0 1em;
  font: {
    weight: 400;
    style: normal;
    size: 1.6rem;
  }
  line-height: 1.5;
  letter-spacing: 0.01rem;
}

.p3 {
  margin: 0 0 1em;
  font: {
    weight: 400;
    style: normal;
    size: 1.3rem;
  }
  line-height: 1.5;
  letter-spacing: 0.01rem;
}

/* Default page fonts */

%standard-type {
  h1 {
    @extend .h1;
  }

  h2 {
    @extend .h2;
  }

  h3 {
    @extend .h3;
  }

  h4 {
    @extend .h4;
  }

  p {
    @extend .p1;
  }

  ul,
  ol {
    @extend .p1;

    &.padded-list li {
      margin-bottom: 1em;
    }
  }

  li:not([class]) {
    @extend .p2;
    margin: 0;
  }

  ul {
    list-style: disc;
  }

  table {
    margin-bottom: 2rem;
    border-collapse: collapse;

    td,
    th {
      @extend .p2;
      padding: 0.25em 0.5em;
      line-height: 1.1;
      text-align: left;
      vertical-align: top;

      &:last-child {
        // padding-right: 0;
      }
    }

    th {
      font-weight: 600;
    }

    &.table-style-1 {
      th {
        color: $white;
        background-color: $black;
      }

      td,
      th {
        text-align: center;
        border: 0.1rem solid $black;
      }
    }

    &.table-style-2 {
      th {
        color: $white;
        background-color: $black;
      }

      td,
      th {
        text-align: left;
        border: 0.1rem solid $black;
      }
    }
  }

  .button {
    margin: 0 0.4rem 0.7rem;
  }
}

a:not([class]) {
  color: $link-color;
  transition: color 150ms;
  text-decoration: none;
  text-decoration-skip: ink;

  &:hover,
  &:focus {
    color: $link-color;
    text-decoration: underline;
  }
}
