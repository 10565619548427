.module-text-with-sidebar {
  position: relative;
  z-index: 10;
  margin: 0;
  padding: 4rem 0; // for the parallax image to extend into
  overflow: hidden;

  @media only screen and (min-width: $screen-sm-min) {
    padding: 7.5rem 0; // for the parallax image to extend into
  }

  & + .module-text-with-sidebar {
    padding-top: 0;
  }

  &__background {
    position: absolute;
    top: 0;
    left: -50%;
    text-align: center;
    width: 200%;
    z-index: 0;

    @media only screen and (min-width: $screen-sm-min) {
      left: 0;
      width: 100%;
    }
  }

  &__content {
    position: relative;
    z-index: 10;
    @extend %standard-type;
  }

  &__sidebar {
    @extend .sidebar;
    position: relative;
    z-index: 10;
  }

  &__specs {
    @extend %spec-table;
    @extend %spec-table--two-col;
    @extend %spec-table--left-headers;
    margin-bottom: 4rem;
  }

  .container--has-sidebar {
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: $screen-sm3-min) {
      flex-direction: row;
      justify-content: space-between;
    }

    .module-text-with-sidebar__content {
      @media only screen and (min-width: $screen-sm3-min) {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: auto;
        width: 58.5%;
      }
    }

    .module-text-with-sidebar__sidebar {
      margin-bottom: 3rem;

      @media only screen and (min-width: $screen-sm3-min) {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: auto;
        width: 33.333333%;
        margin-bottom: 0;
      }
    }

    &.container--sidebar-right {
      @media only screen and (min-width: $screen-sm3-min) {
        flex-direction: row-reverse;

        .module-text-with-sidebar__sidebar {
          // add some space on top to compensate for the content's title, but only when the sidebar is on the right
          margin-top: 6.3rem;
        }
      }
    }

    &.container--sidebar-default {
      // the default widget sidebar appears _below_ the content in mobile. Default is above.
      @media only screen and (max-width: ($screen-sm3-min - 0.1em)) {
        flex-direction: column-reverse;

        .module-text-with-sidebar__sidebar {
          margin-bottom: 0;
        }

        .module-text-with-sidebar__content {
          margin-bottom: 3rem;
        }
      }
    }
  }
}

%spec-table {
  width: 100%;

  table {
    width: 100%;
    border-collapse: collapse;
  }

  &--left-headers {
    th:first-child,
    td:first-child {
      font-weight: 900;
      text-align: left;
    }
  }

  &--two-col {
    td:last-child,
    th:last-child {
      text-align: right;
      padding: 0.5em 0 0.5em 0.5em;
    }
  }

  td,
  th {
    @extend .p1;
    line-height: 1.1;
    font-style: italic;
    padding: 0.5em 0.5em 0.5em 0;
    border-bottom: 0.1rem solid $black;
  }

  th {
    font: {
      weight: 900;
      style: normal;
    }
    text-align: left;
  }
}

.spec-buttons {
  margin-bottom: 4rem;

  .button {
    display: block;
    width: 100%;
    margin-bottom: 2rem;
  }
}
