.content-info {
  position: relative;
  z-index: 10;
  background-color: #121212;
  border-top: 0.2rem solid $orange;

  .container {
    @extend .container--wide;
    position: relative;
  }

  &__brand {
    display: block;
    position: relative;
    z-index: 101;
    width: 18rem;
    max-width: 100%;
    margin: 0 auto 5.4rem;
    padding: 0;

    @media only screen and (min-width: $screen-sm-min) {
      width: 33rem;
      margin: 0 0 3rem;
    }

    @media only screen and (min-width: $screen-md-min) {
      margin: 0 0 8.6rem;
    }

    img:first-child {
      display: block;

      @media only screen and (min-width: $screen-sm-min) {
        display: none;
      }
    }

    img:last-child {
      display: none;

      @media only screen and (min-width: $screen-sm-min) {
        display: block;
      }
    }
  }

  &__first {
    padding: 5rem 0 2.8rem;

    @media only screen and (min-width: $screen-sm-min) {
      padding: 3.8rem 0;
    }
  }

  &__nav-cta {
    @media only screen and (min-width: $screen-sm-min) {
      margin: 0 0 5rem;
    }

    @media only screen and (min-width: $screen-md-min) {
      position: absolute;
      top: 3.4rem;
      right: $side-gutter-desktop--wide;
      margin: 0;
    }

    ul,
    li {
      list-style: none;
      padding: 0;
      margin: 0;

      @media only screen and (min-width: $screen-sm-min) {
        display: inline-block;
      }
    }

    li {
      margin-bottom: 1.5rem;

      @media only screen and (min-width: $screen-sm-min) {
        margin: 0 1.5rem 0 0;
      }

      @media only screen and (min-width: $screen-md-min) {
        margin: 0 0 0 1.5rem;
      }
    }

    a {
      @extend .button;
      @extend .button--white;

      display: block;
      width: 100%;
      max-width: 30rem;
      margin: 0 auto;

      @media only screen and (min-width: $screen-sm-min) {
        margin: 0;
      }
    }
  }

  &__nav-primary {
    display: none;

    @media only screen and (min-width: $screen-sm-min) {
      display: block;
    }

    ul,
    li {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    a {
      display: inline-block;
      color: $white;
      @extend .p2;
      margin: 0;
      line-height: 1;
      text-decoration: none;
      padding: 0.21em 0;

      &:hover,
      &:focus {
        outline: none;
        color: $orange;
        text-decoration: none;
      }
    }

    &-list {
      columns: 6;

      > li {
        break-inside: avoid;

        &:nth-child(5) {
          break-after: column;
        }

        > a {
          @extend .h4;
          margin-bottom: 0.3em;
        }
      }
    }

    li.menu-item-has-children {
      > a:not([href]) {
        cursor: default;
      }
    }

    li.current-menu-item > a {
      color: $orange;
    }

    @supports (display: grid) {
      &-list {
        columns: none;

        @media only screen and (min-width: $screen-sm-min) {
          display: grid;
          grid-gap: 2rem 2rem;

          // 5 columns
          grid-template-columns: repeat(5, 1fr);
        }

        @media only screen and (min-width: $screen-md-min) {
          grid-gap: 0 2rem;
          // six columns, the last must have the remaining items so...
          grid-template-columns: repeat(6, 1fr);
          grid-template-rows: repeat(6, 1fr);
        }

        > li {
          @media only screen and (min-width: $screen-md-min) {
            // ... make the first 5 items span their entire column
            &:nth-child(-n + 5) {
              grid-row: 1 / -1;
            }
          }
        }
      }
    }
  }

  &__second {
    background: $orange-2;
    padding: 0.8rem 0;

    @media only screen and (min-width: $screen-sm-min) {
      padding: 2.1rem 0;
    }

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__copyright {
    display: none;
    @extend .h4;
    margin: 0;
    color: $white;

    @media only screen and (min-width: $screen-sm-min) {
      display: block;
    }
  }

  &__nav-legal {
    display: none;
    @extend .p3;
    margin: 0;
    color: $white;

    @media only screen and (min-width: $screen-sm-min) {
      display: block;
    }

    ul,
    li {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    ul {
      text-align: center;
    }

    li {
      display: inline-block;

      &:after {
        content: '';
        vertical-align: middle;
        display: inline-block;
        width: 1px;
        height: 1.2rem;
        border-right: 1px solid $white;
      }

      &:last-child:after {
        display: none;
      }
    }

    a {
      display: inline-block;
      padding: 0 1rem;
      color: $white;
      text-decoration: none;

      &:hover,
      &:focus {
        color: $white;
        text-decoration: underline;
      }
    }
  }

  &__nav-social {
    width: 100%;

    ul,
    li {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    ul {
      text-align: center;

      @media only screen and (min-width: $screen-sm-min) {
        text-align: right;
      }
    }

    li {
      display: inline-block;
      margin-left: 1.3rem;

      &:first-child {
        margin-left: 0;
      }

      &.social-linkedin a {
        background: {
          image: url(../images/linkedin-in-brands.svg);
          size: 1.6rem;
        }
      }

      &.social-facebook a {
        background: {
          image: url(../images/facebook-f-brands.svg);
          size: 0.83rem;
        }
      }

      &.social-youtube a {
        background: {
          image: url(../images/youtube-brands.svg);
          size: 1.6rem;
        }
      }

      &.social-twitter a {
        background: {
          image: url(../images/twitter-brands.svg);
          size: 1.6rem;
        }
      }

      &.social-instagram a {
        background: {
          image: url(../images/instagram-brands.svg);
          size: 1.6rem;
        }
      }
    }

    a {
      display: block;
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.1);
      transition: all 150ms;
      background: {
        repeat: no-repeat;
        position: 50%;
      }

      &:hover,
      &:focus {
        outline: none;
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
  }

  &__copyright,
  &__nav-legal,
  &__nav-social {
    @media only screen and (min-width: $screen-sm-min) {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
      width: 33.3333%;
    }
  }
}

/* Recaptcha */
.recaptcha-disclaimer {
	margin: 2rem 0 0;
	@extend .p3;
	color: $white;
	text-align: right;
}