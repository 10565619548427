.post-single,
.post-feed {
	padding: 4rem 0; // for the parallax image to extend into

	@media only screen and (min-width: $screen-sm-min) {
		padding: 7rem 0; // for the parallax image to extend into
	}

	&__background {
		position: absolute;
		top: $banner-height-mobile + 6rem;
		text-align: center;
		z-index: -1;
		width: 100%;
		height: 100%;

		.parallax {
			width: 100%;
			height: 100%;

			background: {
				position: 50%;
				size: 200%;
				repeat: no-repeat;
			}
		}

		.mm-wrapper_opened & {
			display: none; // hide the background so it doesn't interefere with the mobile menu. "overflow-x: hidden" breaks the parallax.
		}

		@media only screen and (min-width: $screen-sm-min) {
			top: $banner-height-desktop + 12rem;
			left: 0;

			.parallax {
				background: {
					size: contain;
				}
			}
		}
	}

	&__container {
		position: relative;
		z-index: 10;

		@media only screen and (min-width: $screen-sm3-min) {
			display: flex;
			justify-content: space-between;
		}
	}

	&--firstflights {
		.post-feed__container {
			display: grid;
			gap: 3rem;
			grid-template-areas:
				"content"
				"sidebar"
				"articles";

			@media only screen and (min-width: $screen-sm3-min) {
				grid-template-columns: 58.5% 33.333333%;
				grid-template-areas:
					"content sidebar"
					"articles sidebar";
			}

			.post-feed__content {
				grid-area: content;
				margin-bottom: 0;

				@media only screen and (min-width: $screen-sm3-min) {
					width: 100%;
				}
			}

			.post-feed__articles {
				grid-area: articles;

				@media only screen and (min-width: $screen-sm3-min) {
					width: 100%;
				}

				h2 {
					@extend .h2;
				}
			}

			.post-feed__sidebar {
				grid-area: sidebar;

				@media only screen and (min-width: $screen-xs-min) {
					display: grid;
					gap: 2rem;
					grid-template-columns: 1fr 1fr;
					grid-template-areas:
						"cta counts"
						"cta share";
				}

				@media only screen and (min-width: $screen-sm3-min) {
					width: 100%;
					display: block;
				}

				.widget {
					&:nth-child(1) {
						grid-area: cta;
					}
					&:nth-child(2) {
						grid-area: counts;
					}
					&:nth-child(3) {
						grid-area: share;
					}
				}

			}
		}
	}

	&__content {
		@extend %standard-type;
	}

	&__container &__content {
		margin-bottom: 6rem;

		@media only screen and (min-width: $screen-sm3-min) {
			flex-grow: 0;
			flex-shrink: 0;
			flex-basis: auto;
			width: 58.5%;
			margin-bottom: 0;
		}
	}

	&__container &__sidebar {
		@extend .sidebar;

		@media only screen and (min-width: $screen-sm3-min) {
			flex-grow: 0;
			flex-shrink: 0;
			flex-basis: auto;
			width: 33.333333%;
		}
	}

	&__intro {
		@extend %standard-type;
		margin-bottom: 6rem;
	}

	&--faq {
		.post-entry__title {
			@extend .h3;
		}
	}
}

.page-header {
	&__title {
		@extend .h1;
	}
}

.post-entry {
	margin-bottom: 3rem;

	&__meta {
		@extend .p3;
		margin-bottom: 0.5rem;
		color: $orange;
	}

	&__title {
		@extend .h3;
		margin-bottom: 0.3em;

		a {
			color: $black;
			text-decoration: none;

			&:hover,
			&:focus {
				color: $orange;
			}
		}
	}

	&__summary {
		p {
			@extend .p2;
			margin: 0;
		}
	}

	&--firstflight {
		border-top: 0.1rem solid $black;
		padding-top: 4rem;

		.wp-post-image {
			margin-bottom: 2rem;
		}
	}

	&__ff-location {
		font-weight: 400;
	}

	&__ff-aircraft {
		display: block;
		@extend .p2;
		margin: 0.25em 0 0 0;
		font-weight: 600;
	}

	&__event-subtitle {
		display: block;
		@extend .p2;
		margin: 0.25em 0 0 0;
	}

	&__event-name {
		font-weight: 600;
	}

	&--event {
		padding-bottom: 6rem;
		background: {
			image: url(../images/event-not-attending.png);
			position: left bottom;
			size: 10.6rem;
			repeat: no-repeat;
		}

		@media only screen and (min-width: $screen-sm-min) {
			padding-right: 12rem;
			background-position: right top;
			padding-bottom: 0;
		}
	}

	&--vans-attending {
		background-image: url(../images/event-attending.png);
	}
}

.post-meta {
	p {
		@extend .p2;
	}

	&__title {
		@extend .h4;
		margin: 0;
	}

	time {
		@extend .p2;
		color: $orange;
	}

	&__event-time {
		@extend .p1;
		margin: 0;
	}

	&__event-name {
		@extend .h2;
		margin: 0 0 4rem;
	}

	&__event-location {
		display: block;
		@extend .h2;
		font-weight: 400;
		margin: 0;
	}

	&__links {
		margin-top: 4rem;
		.button {
			@extend .button--large;
			width: 100%;
		}
	}
}

.page-header {
	position: relative;
	padding-bottom: 2rem;

	@media only screen and (min-width: $screen-sm-min) {
		padding-bottom: 2rem;
	}
}

.post-single {
	&__title {
		@extend .h1;
		margin: 0;
	}

	&__subtitle {
		display: block;
		@extend .h2;
		margin-top: 0.25em;
	}

	&__header {
		@extend .page-header;

		.back-button {
			position: absolute;
			top: -2.5rem;

			@media only screen and (min-width: $screen-sm-min) {
				top: -4rem;
			}
		}
	}

	&__footer {
		position: relative;
		padding-top: 4rem;

		@media only screen and (min-width: $screen-sm-min) {
			padding-top: 7rem;
		}
	}

	&__gallery {
		margin-bottom: 1rem;
		overflow: hidden;

		.flickity-viewport {
			transition: height 250ms ease-out;
			overflow: hidden;
		}

		&-item {
			width: 100%;

			image,
			video {
				width: 100%;
			}
		}
	}

	&__gallery + .post-entry__meta {
		margin-top: 4rem;
	}

	&__control-gallery {
		margin-bottom: 4rem;
	}

	&__control-gallery {
		&-item {
			width: 7.5rem;
			height: 5rem;
			margin-right: 1rem;
			background: {
				size: cover;
				position: 50%;
				image: url(../images/logo-design.png);
			}

			@media only screen and (min-width: $screen-sm-min) {
				width: 15rem;
				height: 10rem;
			}
		}

		.play-button {
			width: 2.5rem;
			height: 2.5rem;
			background-size: cover;

			@media only screen and (min-width: $screen-sm-min) {
				width: 5rem;
				height: 5rem;
			}
		}
	}

	&--firstflight {
		.post-single__title {
			@extend .h3;
			margin-bottom: 1em;

			a {
				color: $black;
				text-decoration: none;

				&:hover,
				&:focus {
					color: $orange;
				}
			}
		}
	}
}

video {
	position: relative;
	z-index: 90;
}

/* surfaces placed over video element in slider to allow for swipe controls */
.video-mask {
	position: absolute;
	z-index: 100;

	&--1 {
		top: 0;
		left: 0;
		width: 100%;
		height: 30%;
	}
	&--2 {
		top: 65%;
		left: 0;
		width: 100%;
		height: calc(35% - 6rem);
	}
	&--3 {
		top: 0;
		left: 0;
		width: 40%;
		height: calc(100% - 6rem);
	}
	&--4 {
		top: 0;
		right: 0;
		width: 40%;
		height: calc(100% - 6rem);
	}
}
