.sidebar {
  margin: 0;

  .widget {
    margin: 0 0 3rem;

    @media only screen and (min-width: $screen-sm-min) {
      margin: 0 0 4rem;
    }
  }

  // widget title
  h3 {
    @extend .h4;
    margin: 0 0 1.6rem;
    padding: 0 0 0.8em;
    border-bottom: 0.1rem solid $black;
  }

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    @extend .p2;
    line-height: 1.2;
    margin-bottom: 1em;
  }

  a:not([class]) {
    color: $orange;
    text-decoration: none;
    transition: color 150ms;

    &:hover,
    &:focus {
      color: $black;
    }
  }

  .textwidget {
    p {
      @extend .p2;
    }
  }

  .vans-cta {
    padding: 2.9rem 3.1rem;
    background-color: $black;
    border-radius: 3px;

    h3 {
      @extend .h2;
      border: 0;
      color: $white;
      padding: 0;
      margin: 0 0 1em;
    }

    p {
      @extend .p1;
      margin: 0;
      color: $white;
    }

    .button {
      @extend .button--white;
      @extend .button--large;
      width: 100%;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__text {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: auto;
      width: auto;
      margin-bottom: 5rem;
    }

    &__button {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
      width: auto;
    }
  }

  .vans-social-sharing {
    width: 100%;

    a {
      display: inline-block;
      margin: 0 1.3rem 1.3rem 0;
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.3);
      transition: all 150ms;
      background: {
        repeat: no-repeat;
        position: 50%;
      }

      &:hover,
      &:focus {
        outline: none;
        background-color: rgba(0, 0, 0, 0.6);
      }

      &.social-linkedin {
        background: {
          image: url(../images/linkedin-in-brands.svg);
          size: 1.6rem;
        }
      }

      &.social-facebook {
        background: {
          image: url(../images/facebook-f-brands.svg);
          size: 0.83rem;
        }
      }

      &.social-email {
        background: {
          image: url(../images/envelope-solid.svg);
          size: 1.6rem;
        }
      }

      &.social-twitter {
        background: {
          image: url(../images/twitter-brands.svg);
          size: 1.6rem;
        }
      }
    }
  }

  .vans-rvs-completed {
    h3 {
      margin-bottom: 0;
    }

    &__wrapper {
      @extend %spec-table;
      @extend %spec-table--two-col;
      @extend %spec-table--left-headers;

      td:first-child {
        color: $orange;
      }
    }
  }

  select {
    @extend .p3;
    height: 3.5rem;
    font-family: $font-family-sans-serif;
    -webkit-appearance: none;
    margin: 0;
    padding: 0.75rem 1rem;
    color: $black;
    border: 1px solid $black;
    border-radius: 0.4rem;
    line-height: 1;
    display: block;
    width: 100%;
    box-shadow: none;

    background: {
      color: $white;
      image: url("data:image/svg+xml,%3Csvg width='7' height='11' viewBox='0 0 7 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.5 0L0 4.30435H7L3.5 0ZM3.5 11L7 6.69565H0L3.5 11Z' fill='black'/%3E%3C/svg%3E%0A");
      position: right 1rem top 50%;
      size: 0.7rem;
      repeat: no-repeat;
    }

    &:focus {
      outline: none;
    }
  }
}

.widget_categories {
  h3 {
    display: none;
  }
}

.widget.vans-events {
  ul {
    margin-bottom: 4rem;
    list-style: none;
  }

  li {
    list-style: none;
  }

  .button {
    width: 100%;
  }

  .post-entry__meta {
    margin-bottom: 0;
    font-weight: 600;
    color: $black;
  }
}
