.module-hero-aircraft {
  position: relative;
  z-index: 10;
  overflow: hidden;

  @media only screen and (min-width: $screen-sm-min) {
    border-bottom: 0.5rem solid $orange;
  }

  .container {
    @extend .container--wide;
  }

  &__background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; // parallax overflow not necessary when at the top of the page
    background: {
      size: cover;
      position: 50%;
      color: $gray;
    }

    &--focus-right {
      background-position: 65%;
    }

    &--focus-left {
      background-position: 35%;
    }

    @for $i from 0 through 10 {
      &--focus-#{$i * 10} {
        background-position: #{$i * 10%};
      }
    }

    @media only screen and (min-width: $screen-sm-min) {
      background-position: 50%;
    }
  }

  &__content {
    @extend %gradient-overlay;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: calc(100vh - #{$banner-height-mobile});
    min-height: 30rem;
    max-height: 46.5rem;
    padding: 2.5rem 0;
    border-bottom: 0.4rem solid $orange;

    @for $i from 0 through 10 {
      &--gradient-#{10 * $i} {
        &:before {
          opacity: #{0.1 * $i};
        }
      }
    }

    @media only screen and (min-width: $screen-sm-min) {
      height: calc(100vh - #{$banner-height-desktop} - 5rem);
      min-height: 50rem;
      max-height: 70.2rem;
      padding: 6rem 0;
      border-bottom: 0;
    }

    .container {
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      height: 100%;
      z-index: 100;

      @media only screen and (min-width: $screen-sm-min) {
        justify-content: space-between;
      }
    }
  }

  &__overlay {
    width: calc(100% - 3rem);

    @media only screen and (min-width: $screen-sm-min) {
      width: 50%;
      max-width: 50rem;
    }

    &-title {
      @extend .h1;
      margin: 0 0 0.15em;
      text-transform: none;
      color: $white;
    }

    p {
      @extend .h2;
      color: $white;
    }

    br {
      display: none;

      @media only screen and (min-width: $screen-md-min) {
        display: block;
      }
    }
  }

  &__overlay-links {
    display: none;

    @media only screen and (min-width: $screen-sm-min) {
      display: block;
    }

    .button {
      @extend .button--white;

      display: block;
      width: 29rem;
      margin-top: 2rem;
    }
  }

  &__links {
    padding: 2.4rem 0;

    .button {
      @extend .button;

      display: block;
      width: 100%;
      max-width: 30rem;
      margin: 0 auto 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media only screen and (min-width: $screen-sm-min) {
      display: none;
    }
  }

  .next-section-link {
    left: auto;
    right: $side-gutter-mobile--wide;
    z-index: 110;

    @media only screen and (min-width: $screen-sm-min) {
      left: calc(50% - 1rem);
      right: auto;
    }
  }
}
