.aircraft-data-popup {
	@include popup;

  &__header {
    margin: 0 0 2rem;

    @media only screen and (min-width: $screen-sm-min) {
      margin: 0 0 6rem;
      display: flex;
      justify-content: space-between;
    }

    p {
      @extend .p2;
    }
  }

  &__heading,
  &__buttons {
    @media only screen and (min-width: $screen-sm-min) {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
      width: 50%;
    }
  }

  &__title {
    @extend .h2;
    margin: 0 0 0.25em;
  }

  &__tables {
    @media only screen and (min-width: $screen-sm-min) {
      display: flex;
      justify-content: space-between;
      margin: 0 -2rem;
    }
  }

  &__table-group {
    @extend %spec-table;
    margin-bottom: 4rem;

    @media only screen and (min-width: $screen-sm-min) {
      padding: 0 2rem;
      margin: 0;
    }

    &--specs {
      @extend %spec-table--two-col;
      @extend %spec-table--left-headers;
    }
  }

  &__table {
    margin-bottom: 4rem;

    table {
      border-top: 0.3rem solid $white;

      @media only print {
        border-color: $black;
      }

      .print-popup & {
        border-color: $black;
      }
    }

    td,
    th {
      @extend .p2;
      margin: 0;
      border-color: $white;

      @media only print {
        border-color: $black;
      }

      .print-popup & {
        border-color: $black;
      }
    }
  }

  &__table-name {
    @extend .h2;
    margin: 1em 0 0.8em;
  }

  &__buttons {
    @media only screen and (min-width: $screen-sm-min) {
      text-align: right;
    }

    .print-button {
      margin-right: 2rem;
      margin-bottom: 0.5rem;
      vertical-align: top;
    }

    .button {
      @extend .button--white;

      width: 100%;
      max-width: 25rem;
      margin-bottom: 0.5rem;
    }
  }
}