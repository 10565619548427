.module-aircraft-features {
  position: relative;
  z-index: 10;
  overflow: hidden;
  padding: 5rem 0;
  background: {
    color: $black;
  }

  @media only screen and (min-width: $screen-sm-min) {
    padding: 7.5rem 0;
  }

  &__background {
    position: absolute;
    left: 0;
    top: -15%;
    width: 100%;
    height: 150%; // allow for parallax overflow
    background: {
      color: $black;
      image: repeating-linear-gradient(
        135deg,
        transparent,
        transparent 0.8rem,
        rgba(255, 255, 255, 0.04) 0.8rem,
        rgba(255, 255, 255, 0.04) 1.4rem
      );
    }
  }

  &__list {
    position: relative;
    z-index: 100;
    color: $white;
    text-align: center;

    @media only screen and (min-width: $screen-sm-min) {
      display: flex;
      justify-content: space-around;
    }

    &,
    dd,
    dt {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    dt {
      @extend .h4;
      margin-bottom: 1.5em;
    }

    dd {
      @extend .p2;
    }
  }

  &__list-item {
    margin-bottom: 4.6rem;

    @media only screen and (min-width: $screen-sm-min) {
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &-content {
      margin: 0 auto;
      max-width: 34rem;
    }

    @media only screen and (min-width: $screen-sm-min) {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: auto;
      width: 25%;
      padding: 2.3rem 5% 4rem;
      border-right: 0.1rem solid $white;

      &:last-child {
        border-right: 0;
      }
    }
  }
}
